import React from "react";
import MainMenu from "./MainMenu";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <header>
      <Link to="/">
        <h3>Lucas Hopman</h3>
      </Link>
      <MainMenu />
    </header>
  );
};

export default Header;
