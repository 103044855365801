import * as React from "react";

type PropTypes = {
  width: number;
  height: number;
  setVisibility: any;
  visibility: boolean;
};

const HamburgerMenuSVG = ({
  width,
  height,
  setVisibility,
  visibility,
}: PropTypes) => {
  return (
    <svg
      width={width}
      onClick={() => setVisibility(!visibility)}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#ffffff"
        d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z"
      />
    </svg>
  );
};

export default HamburgerMenuSVG;
