import Axios from "axios";

const getAllProjects = async () => {
    let neededData:Array<{name: string; url: string; description: string; }> = [];
    await Axios.get("https://api.github.com/users/LucasDeProgrammeur/repos").then(response => {
        response.data.forEach((element: { name: string; html_url: string; description: string }) => {
            neededData.push({
                "name": element.name,
                "url": element.html_url,
                "description": element.description
            })
        });
 
    })
    return neededData;

}

export default getAllProjects;