import React, { useState } from "react";
import { Link } from "react-router-dom";
import HamburgerMenuSVG from "./HamburgerMenuSVG";
import LeftArrowBackSVG from "./LeftArrowBack";

export const MainMenu = () => {
  const [visibility, setVisibility] = useState(
    window.screen.width > 700 ? true : false
  );

  return (
    <>
      <HamburgerMenuSVG
        visibility={visibility}
        setVisibility={setVisibility}
        width={50}
        height={50}
      />
      <nav style={visibility ? { display: "block" } : { display: "none" }}>
        <ul>
          <Link to="/">
            <li>Home</li>
          </Link>
          <Link to="/Projects">
            <li>Projects</li>
          </Link>
          <LeftArrowBackSVG
            width={40}
            height={40}
            visibility={visibility}
            setVisibility={setVisibility}
          />
        </ul>
      </nav>
    </>
  );
};

export default MainMenu;
