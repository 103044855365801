import React from "react";
import GithubIcon from "../../img/githubicon.png";
import LinkedinIcon from "../../img/linkedinicon.png";
import LinkedinSVG from "../common/LinkedinSVG";
import GithubSVG from "./GithubSVG";

export const Footer = () => {
  return (
    <footer>
      <p>©{new Date().getFullYear()}</p>
      <div className="socials">
        <a href="https://github.com/LucasDeProgrammeur">
          <GithubSVG width={48} height={48} />
        </a>
        <a href="https://www.linkedin.com/in/lucas-hopman-661256180/">
          <LinkedinSVG width={48} height={48} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
