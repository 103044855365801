import React from "react";
import "../styles/summervac.css";
import { withRouter } from "react-router-dom";
import Timer from "./Timer";

export const SummerVacationCountdown = () => {
  let year = new Date().getFullYear();

  return (
    <>
      <h1>Zomervakantie countdown</h1>

     <Timer scheduleDate={new Date(`July 15, ${year} 17:00:00`)} />
    </>
  );
};

export default withRouter(SummerVacationCountdown);
