import React, { useState, useEffect } from "react";

type AppProps = {
  scheduleDate:Date
}


const Timer = ({ scheduleDate }:AppProps):JSX.Element => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let vacationDate = new Date(scheduleDate);

  useEffect(() => {
    setInterval(() => {
      let date = new Date();
      if (vacationDate.getTime() - date.getTime() < 0) vacationDate.setFullYear(vacationDate.getFullYear() + 1)
      let distance = vacationDate.getTime() - date.getTime();
      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
    }, 1000);
  });

  return (
    <div className="timer">
      <div>
        <p id="days">{days}</p>
        <p id="addedText">days</p>
      </div>
      <div>
        <p id="hours">{hours}</p>
        <p id="addedText">hours</p>
      </div>
      <div>
        <p id="minutes">{minutes}</p>
        <p id="addedText">minutes</p>
      </div>
      <div>
        <p id="seconds">{seconds}</p>
        <p id="addedText">seconds</p>
      </div>
    </div>
  );  
};

export default Timer;
