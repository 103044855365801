import React, { Component } from "react";
import Timer from "./Timer";

const NewYearsCountdown = () => {
  return (
    <>
      {" "}
      <h1 className="newYearText" >New year 2021 countdown</h1>
      <Timer scheduleDate={new Date("January 1, 2021 00:00:00")} />
    </> 
  );
};

export default NewYearsCountdown;
