import * as React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Home from "./components/Home";
import { Projects } from "./components/Projects";
import { NotFound } from "./components/NotFound";
import { SummerVacationCountdown } from "./components/SummerVacationCountdown";
import NewYearsCountdown from "./components/NewYearsCountdown";

import {} from "react-router-dom";
import Blog from "./components/Blog";
import { ThemeContext } from "./App";
import { useContext, useEffect } from "react";

export default function Routes() {
  const context = useContext(ThemeContext);
  let path = useLocation().pathname;
  useEffect(() => {
    switch (path.toLowerCase()) {
      case "/blog":
        context.setTheme("blogTheme");
        break;
      default:
        context.setTheme("defaultTheme");
        break;
    }
  }, [context, path]);

  return (
    <>
      <Switch>
        <Route exact path="/" render={(props: any) => <Home {...props} />} />
        <Route path="/Projects" component={Projects} />
        <Route
          path="/summervacationcountdown"
          component={SummerVacationCountdown}
        />
        <Route path="/newyear2021countdown" component={NewYearsCountdown} />
        <Route path="/blog" component={Blog} />
        <Route path="/😂">
          <Redirect to="https://www.youtube.com/watch?v=dQw4w9WgXcQ" />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </>
  );
}
