import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { ThemeContext } from "../App";
import space from "../img/earth.jpg";
import profilepic from "../img/profilepic.png";
import win11 from "../img/win11.jpg";

const Blog = () => {
  const context = useContext(ThemeContext);
  useEffect(() => {
    context.setTheme("other");
  });
  return (
    <>
      <section>
        <h1>Blog</h1>
      </section>
      <div className="blog">
        <section className="blogArticle">
          <img src={win11} />
          <section className="blogArticleContent">
            <h2>
              Automate software installation with Powershell and Winget (and
              some commandline arguments!)
            </h2>
            <div className="blogpostMetaData">
              <img src={profilepic} alt={"author"} />
              <em>06-12-2023</em>
            </div>

            <p>
              If you've used Linux before, you might be familiar with package
              managers, for example apt on Debian, and Pacman on Arch based
              systems. These package managers are great, because it provides a
              single place where you can upgrade, remove, or install software,
              including ones critical to your operating system.
              <br />
              Since 2022, Windows now also has a package manager, built into the
              system. Before that, you had to install third party software like
              Chocolatey. Winget is not going to replace Windows Update when it
              comes to critical packages, but it's a nice tool to quickly
              download third party packages through the commandline.
              <br />
              So why am I talking about Winget? Well, you can incoorporate it in
              a Powershell script, to automate installing software on a
              computer. It's useful if:
            </p>
            <ul>
              <li>You're in a company and you set up computers a lot</li>
              <li>
                You haven't taken the step towards Microsoft 365 (yet), but you
                want a bit of automation, without Intune/Autopilot
              </li>
              <li>
                You install a lot of computers at home and want this part
                automated
              </li>
            </ul>
            <p>
              First of all, we'll create a .ps1 file, and before we add a few
              lines of code, we might want to check if the user's an
              Administrator or not. If you're not an admin, you might get a lot
              of UAC messages.
            </p>
            <code>
              {`$currentPrincipal = New-Object Security.Principal.WindowsPrincipal([Security.Principal.WindowsIdentity]::GetCurrent())
if (!$currentPrincipal.IsInRole([Security.Principal.WindowsBuiltInRole]::Administrator)) {
                 echo "This script is Administrator only!"
                 exit
}`}
            </code>
            <p>
              My bad if the line breaking is a bit scuffed. Either way, this way
              you'll check if a user has the role of an Administrator. Put this
              on top of all the commandlets you'll write underneath. After that,
              you can go wild with whatever you want to install using Winget.
              Open the terminal, and let's see what the ID is for a piece of
              software I want to install. I'd like to have Teamviewer host, so
              let's look it up using:
            </p>
            <code>winget search teamviewer</code>
            <p>
              Looks like it's Teamviewer.Teamviewer.Host, so let's put that in
              our .ps1 file, in a new line.
            </p>
            <code>winget install --id=TeamViewer.TeamViewer.Host -e</code>
            <p>
              The -e argument makes sure that we find the package with the exact
              match we put in.
            </p>
            <p>
              Now, you might be wondering how to have .exe and .msi files
              install without touching anything. It just so happens that .msi
              files have arguments to install them silently. For msi files, you
              can use msiexec, like below:
            </p>
            <code>Msiexec /i "c:\somepath\installer.msi" /qb</code>
            <p>
              This in particular makes the .msi file quiet and only show a basic
              user interface. You can use /? after the .msi file for other
              arguments you could use.
              <br />
              It gets a bit more difficult when it comes to exe files. If you
              open a terminal, and try and run it (& "c:\somefile.exe") with the
              /? argument, you might get to see some arguments you can use. You
              could change the file extension to .zip and see if there's an msi
              hidden in there, but in my case, I had an .exe file where the
              /quiet argument worked just fine. You're at the mercy of the
              software developer, in this case.
              <br />
              <br />
              This is what a script like this could look like when finished:
            </p>
            <code>
              {`$currentPrincipal = New-Object Security.Principal.WindowsPrincipal([Security.Principal.WindowsIdentity]::GetCurrent())
if (!$currentPrincipal.IsInRole([Security.Principal.WindowsBuiltInRole]::Administrator)) {
                 echo "This script is Administrator only!"
                 exit
}

# Install winget packages
winget install TeamViewer.TeamViewer.Host Google.Chrome  -e

# Install .msi and .exe, with * you can have wildcards
& "c:\\somefolder\\someprogram.exe"  /quiet
Msiexec /i "\\\\networkdrive\\folder\\setupversion11*.msi" /qb! /l*v install.log
`}
            </code>
            <p>
              Now you might want to execute the script, open Powershell as
              administrator, and cd to where the script is located. Then run the
              command below (replace the filename) to execute the script and
              temporarily bypass the execution policy, just for this script.
            </p>
            <code>
              powershell.exe -ExecutionPolicy Bypass -File '.\yourfile.ps1'
            </code>
            <p>
              If you want to learn more about Winget, check{" "}
              <a
                href="https://learn.microsoft.com/en-us/windows/package-manager/winget/"
                target="_blank"
              >
                This Microsoft article
              </a>
            </p>
            <p>
              If you encounter any issues, make sure your system is the most
              up-to-date it can be, as Winget is a newer feature.
            </p>
            <p>
              This article will constantly be updated, so I might add new things
              to the script, like downloading and installing the latest version
              of winget in the script, or having this file write errors to a
              file throughout the execution of the script.
            </p>
            <b>EDIT</b>: you can actually install multiple packages within winget.
          </section>
        </section>
        <section className="blogArticle">
          <img src={space} alt={"Earth"} />
          <section className="blogArticleContent">
            <h2>Lucashopman.nl design overhaul</h2>
            <div className="blogpostMetaData">
              <img src={profilepic} alt={"author"} />
              <em>25-05-2023</em>
            </div>
            <p>
              I've decided to dust up the old website and give it a nice, new
              look! It was very necessary, because the old site's design looked
              too flat and dark. In terms of blogposts for now, they are served
              up statically, but in the future I'd like to build a backend REST
              API which will send all the blogposts dynamically.
            </p>
            <h3>Let's go over the changes in this big, new release</h3>
            <ul>
              <li>
                A whole new general look of the website, including new
                components like buttons
              </li>
              <li>
                Animation effects using Javascript's "Intersection observer"
              </li>
              <li>Theme switching using React's "Context"</li>
              <li>Project cards got a slight facelift in terms of sizing</li>
              <li>
                Some self advertising on the site, with two of my favorite
                projects
              </li>
              <li>The addition of a blogpage!</li>
              <li>
                The header and site title have a styling update where the header
                is glassy, and the title has a text gradient
              </li>
              <li>The switch to the Bevan font!</li>
              <li>
                Parallax scrolling with throttling to negate performance loss
                (lodash and stuff)
              </li>
              <li>
                The usage of variables throughout the CSS of this site (wow, CSS
                can do variables? Should've known way sooner)
              </li>
            </ul>
            <p>
              Making the website visually pleasing and updating it is a fun
              endeavour. You can refactor code you made when you hadn't aquired
              as much knowledge about a certain subject, and it's very
              rewarding, seeing your website change its looks in realtime.
            </p>
            <p>
              In terms of other plans for the blog, I'm not 100% set on what
              else to place on it. I will definitely place updates on (web)apps
              here and I might do knowledgebase pieces or quick guides. In the
              meantime, feel free to take a look around here.
            </p>
          </section>
        </section>
      </div>
    </>
  );
};

export default Blog;
