import React from "react";
import { Link } from "react-router-dom";
import CountdownIcon from "../img/countdownicon.png";
import { useState } from "react";
import { useEffect } from "react";
import getAllProjects from "../helpers/githubAPIFunctions";
import ProjectItem from "../components/common/ProjectItem";

export const Projects = () => {
  const [githubData, setGithubData] = useState<
    { name: string; url: string; description: string }[]
  >([]);

  useEffect(() => {
    const retrieveGithubData = async () => {
      setGithubData(await getAllProjects());
    };
    retrieveGithubData();
  }, []);
  return (
    <>
      <section>
        <div className="projectsContainer">
          <Link to="summervacationcountdown">
            <div className="project">
              <img src={CountdownIcon} alt={"countdown"} />
              <p className="projectName">Summer vacation countdown</p>
              <p className="projectDesc">
                Count down until summer vacation (NL) starts
              </p>
            </div>
          </Link>

          {githubData.map((element, index) => {
            return (
              <ProjectItem
                currentCount={index}
                name={element.name}
                url={element.url}
                description={element.description}
                fromGithub={true}
              />
            );
          })}
        </div>
      </section>
    </>
  );
};
