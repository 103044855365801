// Pass a ref to this function, and let it add a class for animation!
// Use this function within the React useEffect function
// Side note: make sure the ref is an array of elements.
// You can do this by pushing the element in, inside the ref prop

const animationClass = "scrollAnimation";

const intersectionInteraction = (entries: Array<IntersectionObserverEntry>) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(animationClass);
    } else {
      entry.target.classList.remove(animationClass);
    }
  });
};

const animateOnIntersection = (itemsToAnimateRef: any) => {
  let observer = new IntersectionObserver(intersectionInteraction);

  if (itemsToAnimateRef.current) {
    itemsToAnimateRef.current.forEach((x: Element) => {
      if (x !== null) observer.observe(x);
    });
  }

  return () => {
    observer.disconnect();
  };
};

export default animateOnIntersection;
