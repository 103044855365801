import React, { useEffect, useRef } from "react";
import scrimblyScreenshot from "../img/scrimblyscreenshot.png";
//const Home = ({ message }: AppProps) => <div>{message}</div>;
import githubLine from "../img/github_line.svg";
import downloadLine from "../img/download_line.svg";
import windowsLine from "../img/windows_line.svg";
import qqLine from "../img/qq_line.svg";
import animateOnIntersection from "../helpers/animateOnIntersection";
import NewsfeedLogo from "../img/newsfeed128.png";
import { Link } from "react-router-dom";

const Home = (): JSX.Element => {
  let animatedItemsRef = useRef([] as Array<HTMLElement>);

  useEffect(() => animateOnIntersection(animatedItemsRef), []);
  return (
    <>
      <section className="landingPage">
        <h1 className="siteTitle">Lucas Hopman</h1>
        <h3 className="titleSmall">Software Developer in The Netherlands</h3>
        <code className={"homeCode"}>
          <p>$[LucasWebsite]/: echo 'Hello!' </p>
        </code>
        <section className="centered-items">
          <a href="https://github.com/LucasDeProgrammeur/">
            <button className="buttonSecondary">
              <img src={githubLine} alt="Github page" />
              Github
            </button>
          </a>
          <Link to="/blog">
            <button className="buttonSecondary">Blog</button>
          </Link>
          <Link to="/Projects">
            <button>Projects</button>
          </Link>
        </section>
      </section>

      <section className="scrimbly">
        <h2 ref={(el) => animatedItemsRef.current.push(el!)}>
          <em>Level up</em> your note taking
        </h2>
        <p>
          Use Scrimbly, the markdown note editor. You can create to-do lists, or
          simple documentation right on the fly.
        </p>

        <button
          onClick={() => {
            window.location.replace(
              "https://github.com/LucasDeProgrammeur/scrimbly/releases/tag/pre-release"
            );
          }}
        >
          <img src={downloadLine} alt="Download icon" />
          <img src={windowsLine} alt="Windows download" />
          <img src={qqLine} alt="Linux download" />
          Get scrimbly
        </button>
        <img
          src={scrimblyScreenshot}
          className="imageRight"
          alt="Scrimbly preview"
        />
      </section>

      <section className="newsFeed">
        <h2 ref={(el) => animatedItemsRef.current.push(el!)}>
          Need Dutch <em>news</em> or weather reporting?
        </h2>
        <p>Read news, and pick your news sources as you go, using Nieuwsfeed</p>
        <button
          onClick={() =>
            window.location.replace("https://nieuwsfeed.lucashopman.nl")
          }
        >
          Visit
        </button>

        <div
          className="feedContainer"
          ref={(el) => animatedItemsRef.current.push(el!)}
        >
          <div className="newsItem newsFeedImage"></div>
          <div className="newsItem newsFeedImage"></div>
          <div className="newsItem newsFeedImage"></div>
          <div className="newsItem newsFeedImage"></div>
          <img src={NewsfeedLogo} width={128} alt={"logo of newsfeed"} />
        </div>
      </section>
    </>
  );
};
export default React.forwardRef(Home);
