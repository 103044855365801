import React from "react";
import "../styles/404.css";
import { Link } from "react-router-dom";

export const NotFound = () => {
  function importAll(r: any) {
    return r.keys().map(r);
  }

  const images = importAll(require.context("../img/404/", false, /\.(gif)$/));
  const number = Math.floor(Math.random() * images.length) + 1;

  return (
    <>
      <section>
        <h1 className="FouroFourText">404</h1>
        <ul className="middleMenu">
          <Link to="/">
            <button>Home</button>
          </Link>
        </ul>
        <img src={images[number]} className="gif" alt={"randomly selected"} />
      </section>
    </>
  );
};

export default NotFound;
